import Script from "next/script";
import type { FunctionComponent } from "react";

// Reference: https://developers.google.com/analytics/devguides/collection/analyticsjs/tracking-snippet-reference#async-minified
export const GoogleAnalyticsScripts: FunctionComponent = () => (
  <>
    <Script
      id="google-analytics"
      src="https://www.google-analytics.com/analytics.js"
      strategy="afterInteractive"
    />
  </>
);

GoogleAnalyticsScripts.displayName = "GoogleAnalyticsScripts";
