import Script from "next/script";
import type { FunctionComponent } from "react";

import { config } from "~/data";

export const IndexExchangeWrapperScripts: FunctionComponent = () => (
  <Script
    id="index-exchange-wrapper-script"
    src={config.advertisement.indexExchangeWrapper.scriptUrl}
    strategy="lazyOnload"
  />
);

IndexExchangeWrapperScripts.displayName = "IndexExchangeWrapperScripts";
