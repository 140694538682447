import { css } from "@emotion/react";
import { fontRoboto } from "@product/scmp-sdk";

import { ZIndexLayer } from "./z-index";

// refs: https://meyerweb.com/eric/tools/css/reset/
export const resetCss = css`
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  sub,
  sup,
  tt,
  var,
  u,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;

    font: inherit;
    font-size: 100%;
    vertical-align: baseline;

    border: 0;
  }
  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol,
  ul {
    list-style: none;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: "";
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
`;
export const globalCss = css`
  ${resetCss}

  html {
    font-size: 14px;
    /* refs: https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-gutter */
    scrollbar-gutter: stable;
  }

  body {
    block-size: 100vh;

    font-family: ${fontRoboto};

    /* stylelint-disable-next-line csstree/validator */
    -webkit-font-smoothing: antialiased;
    -webkit-overflow-scrolling: touch;

    overflow-x: hidden;
  }

  a {
    display: inline-block;

    color: #001246;
    text-decoration: none;
  }

  b {
    font-weight: 700;
  }

  i {
    font-style: italic;
  }

  *,
  *:before,
  *:after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
    padding: 0;

    font-size: 100%;
    font-family: inherit;
    line-height: 1.15;

    border: 0;
  }

  /* Align mobile and desktop background color to white */
  input {
    background-color: #ffffff;
  }

  /* NOTE: hide the captcha badge when user opens the login dialog */
  .grecaptcha-badge {
    display: none;
  }

  input[type="search"] {
    padding: 0;
  }

  /* clears the 'X' from Internet Explorer */
  input[type="search"]::-ms-clear {
    display: none;

    inline-size: 0;
    block-size: 0;
  }
  input[type="search"]::-ms-reveal {
    display: none;

    inline-size: 0;
    block-size: 0;
  }

  /* clears the 'X' from Chrome */
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    display: none;
  }

  .bold-text {
    font-weight: bold;
  }

  /** cspell:disable mastheadrenewpopup */
  #template-builder-container--mastheadrenewpopup > * {
    /** For fixing the renew popup covered by AppBar */
    z-index: ${ZIndexLayer.AppBar.valueOf() + 1};
  }

  #speechify-api-paragraph-hover-button-container {
    z-index: ${ZIndexLayer.AppBar.valueOf() - 1} !important;
  }

  html.tp-scroll-prevented,
  body.offer-meter-open.tp-modal-resizing.tp-modal-open,
  body.offer-meter-open,
  body.tp-modal-resizing,
  body.tp-modal-open,
  body.tp-body-scroll-prevented,
  body.offer-meter-open.tp-modal-open {
    position: static;
    /* stylelint-disable-next-line liberty/use-logical-spec */
    height: auto !important;

    overflow: auto !important;

    touch-action: auto !important;
    -webkit-overflow-scrolling: touch !important;
  }
`;
