import type { YouTubeVideoEvents } from "~/components/tracking/google-analytics/types";
import { removeNullOrUndefinedInObject } from "~/lib/utils";

import type { GA4Event, TrackingEvent, UntypedEvent, UntypedGA4Event } from "./types";

export function sendGTMSubscribeVariables(data: Record<string, unknown>) {
  window.dataLayer?.push({ subscribe: removeNullOrUndefinedInObject(data) });
}

export function setGTMCustomDimension(data: Record<string, unknown>) {
  window.dataLayer?.push({ page: { ...removeNullOrUndefinedInObject(data) } });
}

export function sendGA4GTMTracking<Untyped extends true | false = false>(
  event: Untyped extends true ? UntypedGA4Event : GA4Event,
  _options?: { untyped: Untyped },
) {
  window.dataLayer?.push(event);
}

export function sendGTMTracking<Untyped extends true | false = false>(
  event: Untyped extends true ? UntypedEvent : TrackingEvent,
  _options?: { untyped: Untyped },
) {
  window.dataLayer?.push(event);
}

export function sendVideoTracking(event: YouTubeVideoEvents) {
  sendGTMTracking({ event: "video-tracking", tracking: event });
}

export function trackPageView(data: Record<string, unknown>) {
  // Cspell:ignore Pageview
  sendGTMTracking({
    _clear: true,
    event: "VirtualPageview",
    page: data,
    subscribe: data.subscribe,
  });
}

declare global {
  interface Window {
    dataLayer: Record<string, unknown>[];
  }
}
