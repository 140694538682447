import Script from "next/script";
import type { FunctionComponent } from "react";

export const GooglePublisherTagScripts: FunctionComponent = () => (
  <Script
    id="google-publisher-tag"
    src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
    strategy="lazyOnload"
  />
);

GooglePublisherTagScripts.displayName = "GooglePublisherTagScripts";
