import { NoSsr } from "@mui/base";
import type { PageProps } from "next";
import type { FunctionComponent } from "react";

import { IdleQueue } from "~/components/common/idle-queue";
import { GoogleOneTapLoginContainer } from "~/components/google-one-tap-login";

import {
  DynamicAppBar,
  DynamicContentReactionActionRedirect,
  DynamicHamburgerMenu,
  DynamicLoginDialog,
  DynamicNewsTicker,
  DynamicPopupContainer,
  DynamicSimpleHooksRunner,
} from "./dynamics";

export type Props = {
  pageProps?: PageProps;
};

/**
 * This component is for placing application specific features and also the application header bar
 */
export const AppInitializer: FunctionComponent<Props> = ({ pageProps }) => {
  const application = pageProps?.appInitializerConfiguration?.application ?? "scmp";
  switch (application) {
    case "plus":
      return (
        <>
          <NoSsr>
            <DynamicSimpleHooksRunner />
            <DynamicLoginDialog />
          </NoSsr>
          <DynamicAppBar pageProps={pageProps} />
        </>
      );
    case "posties":
      return (
        <>
          <IdleQueue>
            <DynamicHamburgerMenu />
          </IdleQueue>
          <NoSsr>
            <DynamicSimpleHooksRunner />
            <DynamicLoginDialog />
          </NoSsr>
          <DynamicAppBar pageProps={pageProps} />
        </>
      );
    default:
      return (
        <>
          <IdleQueue>
            <DynamicPopupContainer />
            <DynamicHamburgerMenu />
          </IdleQueue>
          <NoSsr>
            <DynamicSimpleHooksRunner />
            <DynamicContentReactionActionRedirect />
            <DynamicLoginDialog />
          </NoSsr>
          <DynamicAppBar pageProps={pageProps}>
            <NoSsr>
              <IdleQueue>
                <GoogleOneTapLoginContainer />
                <DynamicNewsTicker appBarVariant={pageProps?.appBarConfiguration?.variant} />
              </IdleQueue>
            </NoSsr>
          </DynamicAppBar>
        </>
      );
  }
};

AppInitializer.displayName = "AppInitializer";
