import { createEnvironment } from "@product/scmp-sdk";
import type { Environment } from "relay-runtime";

import { config } from "~/data";

import type { NetworkOptions } from "./network-options";
import { createNetworkOptions } from "./network-options";

export function createBaseClientEnvironment(
  networkOption = {
    credentials: "omit",
  } as NetworkOptions,
) {
  return createEnvironment(
    createNetworkOptions({
      ...networkOption,
    }),
    {},
    {},
  );
}

let clientEnvironmentWithoutCred: Environment;
export function createClientEnvironment() {
  if (typeof window === "undefined") return clientEnvironmentWithoutCred;
  if (clientEnvironmentWithoutCred === undefined) {
    const isPreviewMode = new URLSearchParams(window.location.search).get("action") === "preview";

    clientEnvironmentWithoutCred = createBaseClientEnvironment({
      credentials: isPreviewMode ? "include" : "omit",
    });
  }

  return clientEnvironmentWithoutCred;
}

let clientEnvironmentWithCred: Environment;

export function createClientEnvironmentWithCred() {
  if (typeof window === "undefined") return clientEnvironmentWithCred;
  if (clientEnvironmentWithCred === undefined) {
    clientEnvironmentWithCred = createBaseClientEnvironment({
      credentials: "include",
    });
  }

  return clientEnvironmentWithCred;
}

let clientUserServiceEnvironment: Environment;
export function createClientUserServiceClientEnvironment() {
  if (typeof window === "undefined") return clientUserServiceEnvironment;
  if (clientUserServiceEnvironment === undefined) {
    clientUserServiceEnvironment = createBaseClientEnvironment({
      credentials: "include",
      urlOptions: {
        headers: {
          apikey: config.graphql.userService.apiKey,
        },
        url: config.graphql.userService.host,
      },
    });
  }

  return clientUserServiceEnvironment;
}
