import Script from "next/script";
import type { FunctionComponent } from "react";

export const OnePlusXScripts: FunctionComponent = () => (
  <>
    <Script id="one-plus-x" src="https://cdn.opecloud.com/ope-scmp.js" strategy="lazyOnload" />
  </>
);

OnePlusXScripts.displayName = "OnePlusXScripts";
