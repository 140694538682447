import type { PageProps as NextPageProps } from "next";

import {
  createBaseClientEnvironment,
  createClientEnvironment,
} from "~/lib/relay/environment.client";

export type PageProps = NextPageProps & {
  isUseStaticProps: true;
};

export const createClientEnvironmentByPageProps = (pageProps: PageProps) =>
  // TODO: workaround for using getStaticProps in 404 page
  pageProps.isUseStaticProps ? createBaseClientEnvironment() : createClientEnvironment();
