import type { FunctionComponent } from "react";

import { ChartbeatScripts } from "./chartbeat/scripts";
import { GoogleAnalyticsScripts } from "./google-analytics/scripts";
import { GoogleTagManagerScripts } from "./google-tag-manager/scripts";

export * from "./atoms";
export * from "./hooks";

export const TrackingScripts: FunctionComponent = () => (
  <>
    <ChartbeatScripts />
    <GoogleTagManagerScripts />
    <GoogleAnalyticsScripts />
  </>
);

TrackingScripts.displayName = "TrackingScripts";
