import { useMountEffect } from "@react-hookz/web";

/**
 * For handling the advertisement issue in mobile safari
 */
export const useDisableScrollRestoration = () => {
  useMountEffect(() => {
    history.scrollRestoration = "manual";
  });
};
