import type { HTMLMotionProps } from "framer-motion";
import { m } from "framer-motion";
import { useRouter } from "next/router";
import type { FunctionComponent } from "react";
import React, { useMemo } from "react";

import { getUrlPathWithoutQuery } from "~/lib/utils";

export type Props = HTMLMotionProps<"div"> & {
  animateKey?: string;
};

export const PageTransition: FunctionComponent<Props> = ({ animateKey, children, ...props }) => {
  const { asPath } = useRouter();
  // This is for preventing the transition effect when querystring is changed
  const currentUrl = useMemo(() => getUrlPathWithoutQuery(asPath), [asPath]);
  return (
    <m.div
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
      key={animateKey ?? currentUrl}
      {...props}
    >
      {children}
    </m.div>
  );
};

PageTransition.displayName = "PageTransition";
