import dynamic from "next/dynamic";

import type { Props as SnackbarProviderProps } from "~/components/common/snackbar";
import type { Props as MixpanelProps } from "~/components/mixpanel";
import type { Props as C2paProviderProps } from "~/components/schema-render/common/image/starling-image-popover/contexts";
import type { Props as RosettaContextProviderProps } from "~/lib/rosetta";

import type { Props as SdkContextProviderProps } from "./contexts";

export const DynamicSnackbarProvider = dynamic<SnackbarProviderProps>(() =>
  import("~/components/common/snackbar").then(module => module.SnackbarProvider),
);

export const DynamicMixpanelProvider = dynamic<MixpanelProps>(() =>
  import("~/components/mixpanel").then(module => module.MixpanelProvider),
);

export const DynamicSdkContextProvider = dynamic<SdkContextProviderProps>(() =>
  import("./contexts").then(module => module.SdkContextProvider),
);

export const DynamicRosettaContextProvider = dynamic<RosettaContextProviderProps>(() =>
  import("~/lib/rosetta").then(module => module.RosettaContextProvider),
);

export const DynamicC2paProvider = dynamic<C2paProviderProps>(() =>
  import("~/components/schema-render/common/image/starling-image-popover/contexts").then(
    module => module.C2paProvider,
  ),
);
