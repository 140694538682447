import * as Sentry from "@sentry/browser";
import { ExtraErrorData as ExtraErrorDataIntegration } from "@sentry/integrations";
import { useAtomValue } from "jotai";
import { useEffect } from "react";

import { accountAtom } from "~/lib/account/atoms";

import { supportedBrowsers } from "./supported-browsers";

import { config as commonSentryConfig } from ".";

export const useSentrySetup = () => {
  const { user } = useAtomValue(accountAtom);
  Sentry.init({
    ...commonSentryConfig,
    beforeSend: event => {
      // Filter out those browser is not in our supported list
      if (!supportedBrowsers.test(navigator.userAgent)) return null;
      return event;
    },
    integrations: [new ExtraErrorDataIntegration({ depth: 6 })],
    transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
  });
  useEffect(() => {
    if (!user) return;
    const scope = Sentry.getCurrentScope();
    scope.setUser({
      ...user,
    });
  }, [user]);
};
