import dynamic from "next/dynamic";

import type { Props as AppBarProps } from "~/components/app-bar";
import type { Props as NewsTickerProps } from "~/components/news-ticker";

export const DynamicContentReactionActionRedirect = dynamic<Record<string, never>>(() =>
  import("~/components/content/content-reaction/content-reaction-action-redirect").then(
    module => module.ContentReactionActionRedirect,
  ),
);

export const DynamicAppBar = dynamic<AppBarProps>(() =>
  import("~/components/app-bar").then(module => module.AppBar),
);

export const DynamicLoginDialog = dynamic<Record<string, never>>(() =>
  import("~/components/login-dialog").then(module => module.LoginDialog),
);

export const DynamicSimpleHooksRunner = dynamic(() =>
  import("~/components/simple-hooks-runner").then(module => module.SimpleHooksRunner),
);

export const DynamicNewsTicker = dynamic<NewsTickerProps>(() =>
  import("~/components/news-ticker").then(module => module.NewsTicker),
);

export const DynamicPopupContainer = dynamic<Record<string, never>>(() =>
  import("~/components/popup-container").then(module => module.PopupContainer),
);

export const DynamicHamburgerMenu = dynamic<Record<string, never>>(() =>
  import("~/components/hamburger-menu").then(module => module.HamburgerMenu),
);
