import styled from "@emotion/styled";

import {
  alignmentCssVariableValues,
  setAlignmentVariables,
  setFullViewportWidthStyles,
} from "~/lib/styles";
import type { ResponsiveVariants } from "~/lib/styles/responsive";

type StyledProps = {
  $contentResponsivePadding?: Partial<ResponsiveVariants<number>>;
  $pageResponsivePadding?: Partial<ResponsiveVariants<number>>;
};
export const PageContainer = styled.div<StyledProps>`
  ${props => setAlignmentVariables(props.$contentResponsivePadding, props.$pageResponsivePadding)}

  display: flex;
  flex-flow: column;
  justify-content: stretch;
  align-items: stretch;

  max-inline-size: 1560px;
  min-block-size: 100vh;
  margin: auto;
  padding-inline: ${alignmentCssVariableValues.pageAlignmentPaddingInline};
  box-sizing: content-box;
`;

export const FooterContainer = styled.footer`
  ${setFullViewportWidthStyles()}
`;

export const Content = styled.div`
  flex: 1;

  min-block-size: 50vh;
  padding-inline: ${alignmentCssVariableValues.contentAlignmentPaddingInline};
`;
