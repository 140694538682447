import { cache } from "@emotion/css"; // eslint-disable-line @emotion/no-vanilla
import { CacheProvider, Global } from "@emotion/react";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { theme } from "@product/scmp-sdk";
import { FlagProvider } from "@unleash/nextjs/client";
import { AnimatePresence, domAnimation, LazyMotion } from "framer-motion";
import { Provider as JotaiProvider } from "jotai";
import type { NextApp } from "next";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { RelayEnvironmentProvider } from "react-relay";
import { useRelayNextjs } from "relay-nextjs/app";
import { SWRConfig } from "swr";
import { resolveFetch } from "unleash-proxy-client";

import { AdvertisementScripts } from "~/components/advertisement";
import { AppInitializer } from "~/components/app-initializer";
import { AppLinkingData } from "~/components/app-linking-data";
import { PageTransition } from "~/components/common/page-transition";
import { TrackingScripts, useInitTrackingSetup } from "~/components/tracking";
import { OnePlusXScripts } from "~/components/tracking/one-plus-x";
import { config } from "~/data";
import type { PageProps } from "~/lib/relay-nextjs";
import { createClientEnvironmentByPageProps } from "~/lib/relay-nextjs/helpers";
import { useSentrySetup } from "~/lib/sentry/hooks";
import { globalCss } from "~/lib/styles/global-styles";
import { isExternalScriptsDisabled } from "~/lib/utils";

import { FooterElementID } from "./_app/consts";
import {
  DynamicC2paProvider,
  DynamicMixpanelProvider,
  DynamicRosettaContextProvider,
  DynamicSdkContextProvider,
  DynamicSnackbarProvider,
} from "./_app/dynamics";
import { useDisableScrollRestoration } from "./_app/hooks";
import { Content, FooterContainer, PageContainer } from "./_app/styles";

type FetchFunction_ = NonNullable<ReturnType<typeof resolveFetch>>;

const App: NextApp<PageProps> = ({ Component, err, pageProps }) => {
  const { env: environment, ...relayProps } = useRelayNextjs(pageProps, {
    createClientEnvironment: () => createClientEnvironmentByPageProps(pageProps),
  });

  useSentrySetup();
  useInitTrackingSetup();
  useDisableScrollRestoration();

  const fetch: FetchFunction_ = function (url, options) {
    const fetch_ = resolveFetch() as FetchFunction_;
    const headers = options?.headers as Record<string, string>;
    delete headers["If-None-Match"];
    delete headers["Cache-control"];
    delete options?.cache;
    return fetch_(url, options);
  };

  // For supporting layout per page
  const renderLayout = () => {
    const PageComponent = <Component {...pageProps} {...relayProps} err={err} />;
    if (Component.getLayout) {
      return Component.getLayout(PageComponent);
    }

    return (
      <PageContainer
        $contentResponsivePadding={pageProps.contentConfiguration?.responsivePadding}
        $pageResponsivePadding={pageProps.pageConfiguration?.responsivePadding}
      >
        <AppInitializer pageProps={pageProps} />
        <Content>
          <AnimatePresence initial={false} mode="wait">
            <PageTransition animateKey={pageProps.animateKey}>{PageComponent}</PageTransition>
          </AnimatePresence>
        </Content>
        <FooterContainer id={FooterElementID} />
      </PageContainer>
    );
  };
  return (
    <FlagProvider config={{ fetch, refreshInterval: 3600 }}>
      <AppLinkingData />
      <Global styles={globalCss} />
      <LazyMotion features={domAnimation}>
        <MuiThemeProvider theme={theme}>
          <JotaiProvider>
            {!isExternalScriptsDisabled() && (
              <>
                <OnePlusXScripts />
                <AdvertisementScripts />
                <TrackingScripts />
              </>
            )}

            <SWRConfig
              value={{
                onErrorRetry: (_error, _key, _config, revalidate, { retryCount }) => {
                  if (retryCount >= 5) return;
                  setTimeout(() => revalidate({ retryCount }), 5000);
                },
                provider: () => new Map(),
              }}
            >
              <RelayEnvironmentProvider environment={environment}>
                <DynamicSnackbarProvider>
                  <DynamicMixpanelProvider>
                    <DynamicSdkContextProvider>
                      <DynamicRosettaContextProvider>
                        <DynamicC2paProvider>
                          <GoogleReCaptchaProvider reCaptchaKey={config.account.recaptchaSiteKey}>
                            <CacheProvider value={cache}>{renderLayout()}</CacheProvider>
                          </GoogleReCaptchaProvider>
                        </DynamicC2paProvider>
                      </DynamicRosettaContextProvider>
                    </DynamicSdkContextProvider>
                  </DynamicMixpanelProvider>
                </DynamicSnackbarProvider>
              </RelayEnvironmentProvider>
            </SWRConfig>
          </JotaiProvider>
        </MuiThemeProvider>
      </LazyMotion>
    </FlagProvider>
  );
};

App.displayName = "App";

export default App;
