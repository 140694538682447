import Script from "next/script";
import type { FunctionComponent } from "react";

import { config } from "~/data";

export const GoogleTagManagerScripts: FunctionComponent = () => (
  <>
    <Script
      id="google-tag-manager-head-script"
      src={`https://www.googletagmanager.com/gtm.js?id=${config.googleTagManager.containerId}`}
      strategy="afterInteractive"
    />
  </>
);

GoogleTagManagerScripts.displayName = "GoogleTagManagerScripts";
