import styled from "@emotion/styled";

import { ZIndexLayer } from "~/lib/styles";

type StyledProps = {
  $positionTop: number;
};

export const Container = styled.div<StyledProps>`
  position: absolute;
  inset-inline: 0 0;
  z-index: ${ZIndexLayer.GoogleOneTapSignIn};

  inline-size: min-content;
  margin-inline-start: auto;

  > div {
    inset-block-start: ${props => `${props.$positionTop}px` || 0} !important;
  }
`;
