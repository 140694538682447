import type { FunctionComponent } from "react";

import { AmazonPublisherServiceScripts } from "~/components/advertisement/amazon-publisher-service";
import { GooglePublisherTagScripts } from "~/components/advertisement/google-publisher-tag";
import { IndexExchangeWrapperScripts } from "~/components/advertisement/index-exchange-wrapper";

import { GoogleIMAScripts } from "./google-ima";

export const AdvertisementScripts: FunctionComponent = () => (
  <>
    <AmazonPublisherServiceScripts />
    <IndexExchangeWrapperScripts />
    <GoogleIMAScripts />
    <GooglePublisherTagScripts />
  </>
);

AdvertisementScripts.displayName = "AdvertisementScripts";
